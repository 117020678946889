<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import SettingsLang from "./SettingsLang.svelte";
  export let user_data;
  let tab = "general",
    childTab = "",
    logoImg = "",
    headerImg = "",
    splashImg = "",
    companyAddress = "",
    email = "",
    phone = "",
    timeZone = "",
    compnyDescription = "",
    promoText = "",
    downloadAppLink = "",
    androidLink = "",
    appleLink = "",
    termsService = "",
    privacyPolicy = "",
    facebook = "",
    instagram = "",
    linkedin = "",
    youtube = "",
    google = "",
    appToken = "",
    googleClientId = "",
    googlePlaceId = "",
    googleClientSecret = "",
    googleApiKey = "",
    meruscaseToken = "",
    googlePayMerchantId = "",
    applePayMerchantId = "",
    squarePayToken = "",
    firstLoading = false,
    lang_english =
      '{"lang":"english","slides":[null,["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_1.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687480448104400568448142000901_english_2.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_3.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_4.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_5.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_6.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_7.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_8.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687480448104400568448142000901_english_9.jpeg"],["https://qix.cloud/ajax/images/app_settings/1687193204104400568448142000901_english_10.jpeg"]],"links":[null,"https://qix.cloud","https://qix.cloud","https://qix.cloud","https://qix.cloud","https://qix.cloud","https://qix.cloud",null,"https://www.youtube.com/"]}',
    lang_espanol = "",
    lang_francais = "",
    lang_chinese = "",
    r_english = "",
    r_espanol = "",
    r_francais = "",
    r_chinese = "";
  let glossaries = [];
  let questions = [];
  let articles;
  let statuses;
  let sending = false;
  let branding_count = "";
  let details_count = "";
  let links_count = "";
  let tokens_count = "";
  const dispatch = createEventDispatcher();
  //const currentYear = new Date().getFullYear();
  onMount(async () => {
    let article_tmp = [
      { img: "", title: "", caption: "", description: "" },
      { img: "", title: "", caption: "", description: "" },
      { img: "", title: "", caption: "", description: "" },
      { img: "", title: "", caption: "", description: "" },
      { img: "", title: "", caption: "", description: "" },
    ];
    articles = {
      english: article_tmp,
      espanol: article_tmp,
      francais: article_tmp,
      chinese: article_tmp,
    };
    let statuses_tmp = [
      { name: "", TagId: "", description: "" },
      { name: "", TagId: "", description: "" },
      { name: "", TagId: "", description: "" },
      { name: "", TagId: "", description: "" },
      { name: "", TagId: "", description: "" },
    ];
    statuses = {
      english: statuses_tmp,
      espanol: statuses_tmp,
      francais: statuses_tmp,
      chinese: statuses_tmp,
    };
    setTimeout(() => {
      getAppSettings();
    }, 1000);
  });
  function onHide() {
    dispatch("hide");
  }
  function changeChildTab(t) {
    childTab = childTab != t ? t : "";
  }
  async function onSelectImageLogo(e) {
    const file = e.target.files[0];
    logoImg = await readAsDataURL(file);
    //const img = await readAsImage(url);
    e.target.value = null;
  }
  async function onSelectImageHeader(e) {
    const file = e.target.files[0];
    headerImg = await readAsDataURL(file);
    e.target.value = null;
  }
  async function onSelectImageSplash(e) {
    const file = e.target.files[0];
    splashImg = await readAsDataURL(file);
    e.target.value = null;
  }
  function updateLanguageSetting(payload) {
    if (payload.lang == "english") {
      lang_english = JSON.stringify(payload);
    } else if (payload.lang == "espanol") {
      lang_espanol = JSON.stringify(payload);
    } else if (payload.lang == "francais") {
      lang_francais = JSON.stringify(payload);
    } else if (payload.lang == "chinese") {
      lang_chinese = JSON.stringify(payload);
    }
    // langSettings[payload.lang] = [];
    // langSettings[payload.lang]["links"] = payload.links;
    // langSettings[payload.lang]["slides"] = payload.slides;
  }
  function updateExtraData(payload) {
    if (payload.type == "glossary") {
      glossaries = payload.glossaries;
    } else if (payload.type == "question") {
      questions = payload.questions;
    } else if (payload.type == "status") {
      statuses[payload.lang] = payload.statuses;
    } else if (payload.type == "article") {
      articles[payload.lang] = payload.articles;
    }
  }
  async function calcSettingsCounts() {
    let b_count = 0;
    let d_count = 0;
    let l_count = 0;
    let t_count = 0;
    if (logoImg != "") b_count++;
    if (headerImg != "") b_count++;
    if (splashImg != "") b_count++;
    if (companyAddress != "") d_count++;
    if (email != "") d_count++;
    if (phone != "") d_count++;
    if (timeZone != "") d_count++;
    if (compnyDescription != "") d_count++;
    if (promoText != "") d_count++;
    if (downloadAppLink != "") l_count++;
    if (androidLink != "") l_count++;
    if (appleLink != "") l_count++;
    if (termsService != "") l_count++;
    if (privacyPolicy != "") l_count++;
    if (facebook != "") l_count++;
    if (instagram != "") l_count++;
    if (linkedin != "") l_count++;
    if (youtube != "") l_count++;
    if (google != "") l_count++;
    if (appToken != "") t_count++;
    if (googleClientId != "") t_count++;
    if (googlePlaceId != "") t_count++;
    if (googleClientSecret != "") t_count++;
    if (googleApiKey != "") t_count++;
    if (meruscaseToken != "") t_count++;
    if (googlePayMerchantId != "") t_count++;
    if (applePayMerchantId != "") t_count++;
    if (squarePayToken != "") t_count++;
    branding_count = b_count + "/3";
    details_count = d_count + "/6";
    links_count = l_count + "/10";
    tokens_count = t_count + "/9";
  }
  async function saveAppSettings() {
    if (user_data["user_id"] == "") return false;
    sending = true;
    let body = new FormData();
    body.append("act", "update");
    body.append("auth", user_data["auth"]);
    body.append("user_id", user_data["user_id"]);
    body.append("logoImg", logoImg);
    body.append("headerImg", headerImg);
    body.append("splashImg", splashImg);
    body.append("companyAddress", companyAddress);
    body.append("email", email);
    body.append("phone", phone);
    body.append("timeZone", timeZone);
    body.append("compnyDescription", compnyDescription);
    body.append("promoText", promoText);
    body.append("downloadAppLink", downloadAppLink);
    body.append("androidLink", androidLink);
    body.append("appleLink", appleLink);
    body.append("termsService", termsService);
    body.append("privacyPolicy", privacyPolicy);
    body.append("facebook", facebook);
    body.append("instagram", instagram);
    body.append("linkedin", linkedin);
    body.append("youtube", youtube);
    body.append("google", google);
    body.append("appToken", appToken);
    body.append("googleClientId", googleClientId);
    body.append("googlePlaceId", googlePlaceId);
    body.append("googleClientSecret", googleClientSecret);
    body.append("googleApiKey", googleApiKey);
    body.append("meruscaseToken", meruscaseToken);
    body.append("googlePayMerchantId", googlePayMerchantId);
    body.append("applePayMerchantId", applePayMerchantId);
    body.append("squarePayToken", squarePayToken);
    body.append("lang_english", lang_english);
    body.append("lang_espanol", lang_espanol);
    body.append("lang_francais", lang_francais);
    body.append("lang_chinese", lang_chinese);
    body.append("glossaries", JSON.stringify(glossaries));
    body.append("questions", JSON.stringify(questions));
    body.append("articles", JSON.stringify(articles));
    body.append("statuses", JSON.stringify(statuses));
    const res = await fetch("https://qix.cloud/ajax/app_settings.php", {
      method: "POST",
      body: body,
    });
    sending = false;
    calcSettingsCounts();
  }
  function maketoken(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  async function getAppSettings() {
    let body = new FormData();
    body.append("act", "get");
    body.append("user_id", user_data["user_id"]);
    const res = await fetch("https://qix.cloud/ajax/app_settings.php", {
      method: "POST",
      body: body,
    });
    let data = await res.json();
    if (data["settings"] != "") {
      console.log("data", data);
      let r_branding = JSON.parse(data["settings"].branding);
      let r_details = JSON.parse(data["settings"].details);
      let r_links = JSON.parse(data["settings"].links);
      let r_tokens = JSON.parse(data["settings"].tokens);
      lang_english = data["settings"].lang_english;
      lang_espanol = data["settings"].lang_espanol;
      lang_francais = data["settings"].lang_francais;
      lang_chinese = data["settings"].lang_chinese;
      r_english = JSON.parse(data["settings"].lang_english);
      r_espanol = JSON.parse(data["settings"].lang_espanol);
      r_francais = JSON.parse(data["settings"].lang_francais);
      r_chinese = JSON.parse(data["settings"].lang_chinese);
      glossaries = data["glossaries"];
      questions = data["questions"];
      articles = JSON.parse(data["settings"].articles);
      statuses = JSON.parse(data["settings"].statuses);
      logoImg = r_branding.logoImg;
      headerImg = r_branding.headerImg;
      splashImg = r_branding.splashImg;
      companyAddress = r_details.companyAddress;
      email = r_details.email;
      phone = r_details.phone;
      timeZone = r_details.timeZone;
      compnyDescription = r_details.compnyDescription;
      promoText = r_details.promoText;
      downloadAppLink = r_links.downloadAppLink;
      androidLink = r_links.androidLink;
      appleLink = r_links.appleLink;
      termsService = r_links.termsService;
      privacyPolicy = r_links.privacyPolicy;
      facebook = r_links.facebook;
      instagram = r_links.instagram;
      linkedin = r_links.linkedin;
      youtube = r_links.youtube;
      google = r_links.google;
      appToken = r_tokens.appToken == "" ? maketoken(12) : r_tokens.appToken;
      googleClientId = r_tokens.googleClientId;
      googlePlaceId = r_tokens.googlePlaceId;
      googleClientSecret = r_tokens.googleClientSecret;
      googleApiKey = r_tokens.googleApiKey;
      meruscaseToken = r_tokens.meruscaseToken;
      googlePayMerchantId = r_tokens.googlePayMerchantId;
      applePayMerchantId = r_tokens.applePayMerchantId;
      squarePayToken = r_tokens.squarePayToken;
      calcSettingsCounts();
    } else {
      appToken = maketoken(12);
    }
    firstLoading = true;
  }
</script>

{#if firstLoading}
  <div class="settings-wrap container">
    <div class="w-full">
      <div class="header flex">
        <span class:act={tab == "general"} on:click={() => (tab = "general")}
          >General</span
        >
        <span class:act={tab == "language"} on:click={() => (tab = "language")}
          >Language</span
        >
        <span class:act={tab == "preview"} on:click={() => (tab = "preview")}
          >Preview</span
        >
      </div>
      <div style="text-align: right;height: 35px;">
        <button
          class="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 md:px-4 rounded"
          class:hidden={sending}
          on:click={saveAppSettings}>Save</button
        >
        <img
          src="/loading.gif"
          style="max-width: 30px;float: right;"
          class:hidden={!sending}
        />
      </div>
      <div class="body">
        <div class="general" class:hidden={tab != "general"}>
          <div
            class="itemHead flex"
            on:click={() => changeChildTab("branding")}
          >
            <span>Branding {branding_count}</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "branding"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "branding"}
            />
          </div>
          <div
            class="contant branding flex"
            class:hidden={childTab != "branding"}
          >
            <div>
              <label
                class="logo flex justify-center items-center cursor-pointer"
                for="logoImg"
                style={logoImg != "" ? "background:url(" + logoImg + ")" : ""}
              >
                <img src="/image.svg" />
              </label>
              <input
                type="file"
                id="logoImg"
                name="logoImg"
                class="hidden"
                on:change={onSelectImageLogo}
              />
              <div class="text title">Logo</div>
              <div class="text size">512 × 512</div>
            </div>
            <div>
              <label
                class="header flex justify-center items-center cursor-pointer"
                for="headerImg"
                style={headerImg != ""
                  ? "background:url(" + headerImg + ")"
                  : ""}
              >
                <img src="/image.svg" />
              </label>
              <input
                type="file"
                id="headerImg"
                name="headerImg"
                class="hidden"
                on:change={onSelectImageHeader}
              />
              <div class="text title">Header</div>
              <div class="text size">3000 × 1200</div>
            </div>
            <div>
              <label
                class="splash flex justify-center items-center cursor-pointer"
                for="splashImg"
                style={splashImg != ""
                  ? "background:url(" + splashImg + ")"
                  : ""}
              >
                <img src="/image.svg" />
              </label>
              <input
                type="file"
                id="splashImg"
                name="splashImg"
                class="hidden"
                on:change={onSelectImageSplash}
              />
              <div class="text title">Splash</div>
              <div class="text size">512 × 512</div>
            </div>
          </div>
          <div class="itemHead flex" on:click={() => changeChildTab("details")}>
            <span>Details {details_count}</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "details"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "details"}
            />
          </div>
          <div
            class="contant flex details"
            class:hidden={childTab != "details"}
          >
            <div class="half">
              <input
                type="text"
                placeholder="Company Address"
                bind:value={companyAddress}
              />
              <input type="text" placeholder="Email" bind:value={email} />
              <input type="text" placeholder="Phone" bind:value={phone} />
              <input
                type="text"
                placeholder="Primary Time Zone"
                bind:value={timeZone}
              />
            </div>
            <div class="half">
              <textarea
                placeholder="Company Description"
                bind:value={compnyDescription}
              />
              <textarea placeholder="Promo Text" bind:value={promoText} />
            </div>
          </div>
          <div class="itemHead flex" on:click={() => changeChildTab("links")}>
            <span>Links {links_count}</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "links"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "links"}
            />
          </div>
          <div class="contant flex links" class:hidden={childTab != "links"}>
            <div class="half">
              <input
                type="text"
                placeholder="Download App Link"
                bind:value={downloadAppLink}
              />
              <input
                type="text"
                placeholder="Android Link"
                bind:value={androidLink}
              />
              <input
                type="text"
                placeholder="Apple Link"
                bind:value={appleLink}
              />
              <input
                type="text"
                placeholder="Terms of Service Link"
                bind:value={termsService}
              />
              <input
                type="text"
                placeholder="Privacy Ploicy"
                bind:value={privacyPolicy}
              />
            </div>
            <div class="half">
              <input type="text" placeholder="Facebook" bind:value={facebook} />
              <input
                type="text"
                placeholder="Instagram"
                bind:value={instagram}
              />
              <input type="text" placeholder="Linkedin" bind:value={linkedin} />
              <input type="text" placeholder="Youtube" bind:value={youtube} />
              <input type="text" placeholder="Google" bind:value={google} />
            </div>
          </div>
          <div class="itemHead flex" on:click={() => changeChildTab("tokens")}>
            <span>Tokens {tokens_count}</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "tokens"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "tokens"}
            />
          </div>
          <div class="contant tokens" class:hidden={childTab != "tokens"}>
            <div class="text title">App Settings API</div>
            <input type="text" placeholder="Token" bind:value={appToken} />
            <div class="text title">Google</div>
            <div class="flex">
              <div class="half">
                <input
                  type="text"
                  placeholder="Google Client ID"
                  bind:value={googleClientId}
                />
                <input
                  type="text"
                  placeholder="Google Place ID"
                  bind:value={googlePlaceId}
                />
              </div>
              <div class="half">
                <input
                  type="text"
                  placeholder="Google Client Secret"
                  bind:value={googleClientSecret}
                />
                <input
                  type="text"
                  placeholder="Google API Key"
                  bind:value={googleApiKey}
                />
              </div>
            </div>
            <div class="text title">Other</div>
            <div class="flex">
              <div class="half">
                <input
                  type="text"
                  placeholder="Meruscase Token"
                  bind:value={meruscaseToken}
                />
                <input
                  type="text"
                  placeholder="Google Pay Merchant ID"
                  bind:value={googlePayMerchantId}
                />
              </div>
              <div class="half">
                <input
                  type="text"
                  placeholder="Apple Pay Merchant ID"
                  bind:value={applePayMerchantId}
                />
                <input
                  type="text"
                  placeholder="Square Pay Token"
                  bind:value={squarePayToken}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="language" class:hidden={tab != "language"}>
          <div class="itemHead flex" on:click={() => changeChildTab("english")}>
            <span>English</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "english"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "english"}
            />
          </div>
          <div class="contant" class:hidden={childTab != "english"}>
            <SettingsLang
              lang="english"
              settings={r_english}
              {glossaries}
              {questions}
              articles={articles.english}
              statuses={statuses.english}
              user_id={user_data["user_id"]}
              on:update={(e) => updateLanguageSetting(e.detail)}
              on:updateExtraData={(e) => updateExtraData(e.detail)}
            />
          </div>
          <div class="itemHead flex" on:click={() => changeChildTab("espanol")}>
            <span>Espanol</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "espanol"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "espanol"}
            />
          </div>
          <div class="contant" class:hidden={childTab != "espanol"}>
            <SettingsLang
              lang="espanol"
              settings={r_espanol}
              {glossaries}
              {questions}
              articles={articles.espanol}
              statuses={statuses.espanol}
              user_id={user_data["user_id"]}
              on:update={(e) => updateLanguageSetting(e.detail)}
              on:updateExtraData={(e) => updateExtraData(e.detail)}
            />
          </div>
          <div
            class="itemHead flex"
            on:click={() => changeChildTab("francais")}
          >
            <span>Francais</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "francais"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "francais"}
            />
          </div>
          <div class="contant" class:hidden={childTab != "francais"}>
            <SettingsLang
              settings={r_francais}
              lang="francais"
              {glossaries}
              {questions}
              articles={articles.francais}
              statuses={statuses.francais}
              user_id={user_data["user_id"]}
              on:update={(e) => updateLanguageSetting(e.detail)}
              on:updateExtraData={(e) => updateExtraData(e.detail)}
            />
          </div>
          <div class="itemHead flex" on:click={() => changeChildTab("chinese")}>
            <span>Chinese</span>
            <img
              class=""
              src="/arrow_drop_down.svg"
              class:hidden={childTab == "chinese"}
            />
            <img
              class=""
              src="/arrow_drop_up.svg"
              class:hidden={childTab != "chinese"}
            />
          </div>
          <div class="contant" class:hidden={childTab != "chinese"}>
            <SettingsLang
              lang="chinese"
              settings={r_chinese}
              {glossaries}
              {questions}
              articles={articles.chinese}
              statuses={statuses.chinese}
              user_id={user_data["user_id"]}
              on:update={(e) => updateLanguageSetting(e.detail)}
              on:updateExtraData={(e) => updateExtraData(e.detail)}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
{:else}
  <div
    class="w-full flex-grow flex justify-center items-center"
    style="height: 100vh;position: fixed;background: #fff;top: 0;left: 0;z-index: 99;"
  >
    <img src="/EmoteHi.gif" />
  </div>
{/if}

<style>
  input[type="text"],
  textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .settings-wrap {
    position: relative;
    background: #edf2f6;
    padding: 20px 30px;
    border-radius: 15px;
    max-width: 768px;
  }
  .settings-wrap .header span {
    padding: 5px 15px;
    border-radius: 20px;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  .settings-wrap .header span.act {
    background: #c1ddf0;
  }
  .settings-wrap .itemHead {
    justify-content: space-between;
    background: #cfe6f4;
    padding: 10px 15px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
  }
  .settings-wrap .body > div {
    animation: fadeIn 1s;
  }
  .settings-wrap .contant {
    padding: 10px;
    animation: fadeIn 1s;
  }
  .contant {
    margin-top: 15px;
  }
  .contant.branding,
  .contant.details,
  .contant.links,
  .contant.tokens > .flex {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contant.branding > div > label.flex {
    min-height: 100px;
    background: #7a7e81;
    margin-top: 10px;
    border: 1px solid #7a7e81;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .contant.branding > div > label.logo {
    min-width: 100px;
    border-radius: 10px;
  }
  .contant.branding > div > label.header {
    min-width: 250px;
    border-radius: 10px;
  }
  .contant.branding > div > label.splash {
    min-width: 100px;
    border-radius: 50%;
  }
  .contant.branding .text {
    text-align: center;
  }
  div.text.title {
    font-size: 20px;
    margin-top: 15px;
  }

  .contant .half {
    max-width: 48%;
  }
  .contant.details textarea {
    min-height: 78px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
