<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let articles;
  async function render() {
    console.log("articles", articles);
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  onMount(render);

  async function onSelectImageSlide(e, idx) {
    const file = e.target.files[0];
    let image = await readAsDataURL(file);
    let tmp = articles;
    tmp[idx].img = image;
    articles = [];
    for (let i = 0; i < tmp.length; i++) {
      articles = [...articles, tmp[i]];
    }
    e.target.value = null;
    update();
  }
  function update() {
    dispatch("update", {
      type: "article",
      lang: lang,
      articles: articles,
    });
  }
  function adjustText(idx) {
    let tmp = articles;
    if (tmp[idx].description.length > 875) {
      tmp[idx].description = tmp[idx].description.substring(0, 875);
    }
    articles = [];
    for (let i = 0; i < tmp.length; i++) {
      articles = [...articles, tmp[i]];
    }
    update();
  }
</script>

<div class="article-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Article</div>
  <div class="w-full flex" style="justify-content: center;flex-wrap: wrap;">
    {#each articles as data, idx}
      <div>
        <label
          class="logo flex justify-center items-center cursor-pointer"
          for="{lang}article{idx}"
          style={data.img !== "" ? "background:url(" + data.img + ")" : ""}
        >
          <img src="/image.svg" />
        </label>
        <input
          type="text"
          placeholder="Title"
          bind:value={data.title}
          on:blur={update}
        />
        <input
          type="text"
          placeholder="Caption"
          bind:value={data.caption}
          on:blur={update}
        />
        <textarea
          placeholder="Desription"
          bind:value={data.description}
          on:input={() => adjustText(idx)}
          on:blur={update}
        />
        <span class="badge">{data.description.length}/875</span>
        <input
          type="file"
          id="{lang}article{idx}"
          name="{lang}article{idx}"
          class="hidden"
          on:change={(event) => onSelectImageSlide(event, idx)}
        />
      </div>
    {/each}
  </div>
</div>

<style>
  .back:hover {
    cursor: pointer;
  }
  .article-lang-settings-wrap input[type="text"],
  .article-lang-settings-wrap textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .article-lang-settings-wrap textarea {
    resize: none;
    height: 100px;
  }
  .article-lang-settings-wrap > div > div {
    position: relative;
    min-width: 100px;
    width: 30%;
    padding: 1%;
  }
  .article-lang-settings-wrap label.flex {
    min-height: 100px;
    /* background: #7a7e81; */
    margin-top: 10px;
    border: 1px solid #7a7e81;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    border-radius: 10px;
  }
  .badge {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 12px;
  }
</style>
