<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let settings;
  let tab = "";
  async function render() {}
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  onMount(render);
</script>

<div class="misc-lang-settings-wrap container">
  <div class="back" on:click={gotoBack}>&lt; Misc</div>
</div>

<style>
  .back:hover {
    cursor: pointer;
  }
</style>
