<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  export let lang;
  export let glossaries;
  export let user_id;
  let newId = 0;
  async function render() {
    console.log("user_id", user_id);
  }
  function gotoBack() {
    dispatch("back", {
      tab: "",
    });
  }
  function update() {
    dispatch("update", {
      type: "glossary",
      lang: lang,
      glossaries: glossaries,
    });
  }
  async function remove(id) {
    let body = new FormData();
    body.append("act", "deleteGlossary");
    body.append("id", id);
    const res = await fetch("https://qix.cloud/ajax/app_settings.php", {
      method: "POST",
      body: body,
    });
    let tmp = glossaries;
    glossaries = [];
    for (let i = 0; i < tmp.length; i++) {
      if (id != tmp[i].id) {
        glossaries = [...glossaries, tmp[i]];
      }
    }
    update();
  }
  function addNew() {
    newId++;
    glossaries = [
      ...glossaries,
      {
        id: lang + "_" + newId,
        user_id: user_id,
        lang: lang,
        name: "",
        description: "",
        created: "",
      },
    ];
    console.log("glossaries", glossaries);
    update();
  }
  onMount(render);
</script>

<div class="glossary-lang-settings-wrap container">
  <div class="flex items-center">
    <div class="back" on:click={gotoBack}>&lt; Glossary</div>
    <button
      class="addBtn w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 md:px-4 rounded"
      on:click={addNew}>+ Add</button
    >
  </div>
  <table>
    <tr><th>Name</th><th>Desciption</th><th /></tr>
    {#each glossaries as data}
      {#if data.lang == lang}
        <tr>
          <td>
            <input type="text" bind:value={data.name} on:blur={() => update} />
          </td>
          <td>
            <textarea bind:value={data.description} on:blur={() => update} />
          </td>
          <td style="vertical-align:middle;"
            ><img
              src="/delete.png"
              class="cursor-pointer"
              style="width: 20px;"
              on:click={() => remove(data.id)}
            /></td
          >
        </tr>
      {/if}
    {/each}
  </table>
</div>

<style>
  .back {
    margin-right: 30px;
  }
  .back:hover {
    cursor: pointer;
  }
  .glossary-lang-settings-wrap input[type="text"],
  .glossary-lang-settings-wrap textarea {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
  }
  .glossary-lang-settings-wrap table {
    width: 100%;
    margin-top: 20px;
  }
  .glossary-lang-settings-wrap textarea {
    height: 36px;
  }
  .glossary-lang-settings-wrap table td {
    vertical-align: top;
  }
</style>
