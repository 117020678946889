<script>
  import { onMount, setContext, getContext } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "./Tailwind.svelte";
  import LeftMenu from "./LeftMenu.svelte";
  import Settings from "./Settings.svelte";
  import prepareAssets, { fetchFont } from "./utils/prepareAssets.js";
  import { clickOutside } from "./utils/clickOutside.js";
  import { ggID } from "./utils/helper.js";
  import Background from "./Background.svelte";
  const genID = ggID();
  let searchKey = "";
  let verificationCode = "";
  let loginPanel = false;
  let user_name = "";
  let user_pw = "";
  let user_data = [];
  let blog = "";
  let blog_slug = "";
  let loginError = "";
  let showMenu = false;
  let firstLoading = false;
  let logoutpanel = false;
  let displayLeftMenu = false;
  let loginOnly = false;
  let twoFactAuthPanel = false;
  let shortName;
  onMount(async () => {
    try {
      await initialFunc();
      let data = localStorage.getItem("user_data");
      //console.log("data", data);
      user_data["user_id"] = "";
      if (data) {
        let obj = JSON.parse(data);
        //console.log("obj", obj);
        if (obj.time) {
          let cTime = Date.now();
          if (obj.auth == "wp" && blog != "") {
            if (cTime - obj.time < 60 * 60 * 24 * 3 * 1000) {
              if (obj.path != "/" + blog_slug + "/") {
                window.parent.location.href =
                  "https://qix.cloud/app" + obj.path;
              }
              user_data["user_id"] = obj.ID;
              user_data["display_name"] = obj.display_name;
              shortName = obj.display_name.substr(0, 1);
              user_data["user_email"] = obj.user_email;
              user_data["auth"] = obj.auth;
              obj.time = cTime;
              //console.log("obj", obj);
              localStorage.setItem("user_data", JSON.stringify(obj));
            }
          } else if (obj.auth == "google") {
            if (
              blog_slug != null &&
              blog_slug != "undefined" &&
              blog_slug != ""
            )
              window.parent.location.href = "https://qix.cloud/app/";
            if (cTime - obj.time < 60 * 60 * 24 * 5 * 1000) {
              user_data["user_id"] = obj.ID;
              user_data["display_name"] = obj.display_name;
              shortName = obj.display_name.substr(0, 1);
              user_data["user_email"] = obj.user_email;
              user_data["auth"] = obj.auth;
              user_data["blog_id"] = blog.blog_id;

              //await updateUserDataDB(data.id, JSON.stringify(obj));
              blog = { siteurl: "https://qix.cloud" };
            }
          }
        }
      }
      if (user_data["user_id"] == "") {
        loginPanel = true;
      }
      console.log("user", user_data);
      setTimeout(() => {
        // prepareAssets();
      }, 5000);
    } catch (e) {
      console.log(e);
    }
  });
  async function initialFunc() {
    const urlParams = new URLSearchParams(window.location.search);
    blog_slug = urlParams.get("id");
    let token = urlParams.get("token");
    let m_token = urlParams.get("m_token");
    let uid = urlParams.get("u");
    let google_id = urlParams.get("gid");
    console.log("token", token);
    if (token != null && token != "undefined" && token != "") {
      const res = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo?fields=name,email,id",
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await res.json();
      let temp = {};
      blog = { siteurl: "https://qix.cloud", blog_id: 0 };
      temp.auth = "google";
      temp.ID = data.id;
      temp.display_name = data.name != undefined ? data.name : data.email;
      temp.user_email = data.email;
      temp.path = "https://qix.cloud";
      temp.time = Date.now();
      localStorage.setItem("user_data", JSON.stringify(temp));
      await updateUserDataDB(data.id, JSON.stringify(temp));
      window.parent.location.href = "https://qix.cloud/app/";
    } else if (m_token != null && m_token != "undefined" && m_token != "") {
      const res = await fetch("https://graph.microsoft.com/v1.0/me", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + m_token,
        },
      });
      let data = await res.json();
      let temp = {};
      blog = { siteurl: "https://qix.cloud", blog_id: 0 };
      temp.auth = "google";
      temp.ID = data.id;
      temp.display_name =
        data.displayName != undefined && data.displayName != null
          ? data.displayName
          : data.givenName;
      temp.user_email = data.mail != null ? data.mail : data.userPrincipalName;
      if (temp.display_name == null) temp.display_name = temp.user_email;
      temp.path = "https://qix.cloud";
      temp.time = Date.now();
      console.log("temp", data);
      localStorage.setItem("user_data", JSON.stringify(temp));
      await updateUserDataDB(data.id, JSON.stringify(temp));
      window.parent.location.href = "https://qix.cloud/app/";
    } else if (
      blog_slug != null &&
      blog_slug != "undefined" &&
      blog_slug != ""
    ) {
      try {
        const blog_req = await fetch(
          "https://qix.cloud/wp-admin/admin-ajax.php?action=getSiteUrlFromBlogId",
          {
            method: "POST",
            body: JSON.stringify({ id: blog_slug }),
          }
        );
        blog = await blog_req.json();
        if (blog) {
          const temp_req = await fetch(
            blog.siteurl +
              "/wp-admin/admin-ajax.php?action=getTemplateFromCloudFlare",
            {
              method: "POST",
              body: JSON.stringify({
                auth: user_data["auth"],
                user_id: user_data["user_id"],
              }),
            }
          );
          templates = await temp_req.json();
        } else {
          blog = "";
        }
        //console.log("blog", blog);
        if (uid != null && uid != "undefined" && uid != "") {
          let data = localStorage.getItem("user_data");
          if (data) {
            let obj = JSON.parse(data);
            if (obj.ID == uid) {
              obj.time = Date.now();
              obj.auth = "wp";
              obj.path = blog.path;
              uid = 0;
              console.log("user_details", obj);
              localStorage.setItem("user_data", JSON.stringify(obj));
            }
          }
          if (uid > 0) {
            const user_res = await fetch(
              blog.siteurl +
                "/wp-admin/admin-ajax.php?action=getUserDataFromCloudFlare",
              {
                method: "POST",
                body: JSON.stringify({ user_id: uid }),
              }
            );
            let user_details = await user_res.json();
            user_details.data.time = Date.now();
            user_details.data.auth = "wp";
            user_details.data.path = blog.path;
            localStorage.setItem(
              "user_data",
              JSON.stringify(user_details.data)
            );
            console.log("user_details", user_details.data);
          }
          window.parent.location.href = "https://qix.cloud/app" + blog.path;
        }
        setTimeout(() => {
          firstLoading = true;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    } else if (
      google_id != null &&
      google_id != "undefined" &&
      google_id != "" &&
      google_id != 0
    ) {
      const res = await fetch(
        "https://qix.cloud/wp-admin/admin-ajax.php?action=getGoogleUserDataDB",
        {
          method: "POST",
          body: JSON.stringify({ id: google_id }),
        }
      );
      let obj = await res.json();
      if (obj[0] == "no") {
        window.parent.location.href = "https://qix.cloud/app/";
      }
      localStorage.setItem("user_data", JSON.stringify(obj));
      window.parent.location.href = "https://qix.cloud/app/";
    } else {
      firstLoading = true;
    }
  }
  async function updateUserDataDB(id, data) {
    await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=updateGoogleUserDataDB",
      {
        method: "POST",
        body: JSON.stringify({ id: id, data: data }),
      }
    );
  }
  async function checkVerify() {
    console.log(user_data_temp.code);
    if (verificationCode == atob(user_data_temp.code)) {
      user_data["auth"] = "wp";
      user_data["user_id"] = user_data_temp.data.ID;
      user_data["display_name"] = user_data_temp.data.display_name;
      shortName = user_data_temp.data.display_name.substr(0, 1);
      user_data["user_email"] = user_data_temp.data.user_email;
      user_data_temp.data.path = user_data_temp.blog.path;
      user_data_temp.data.time = Date.now();
      user_data_temp.data.auth = "wp";
      localStorage.setItem("user_data", JSON.stringify(user_data_temp.data));
      if (blog != "") {
        if (user_data_temp.blog.path == "/" + blog_slug + "/") {
          loginPanel = false;
          if (!loginOnly) sendPDF();
        } else {
          window.parent.location.href =
            "https://qix.cloud/app" + user_data_temp.blog.path;
        }
      } else {
        window.parent.location.href =
          "https://qix.cloud/app" + user_data_temp.blog.path;
      }
      twoFactAuthPanel = false;
    }
  }
  async function checkSignIn() {
    try {
      loginError = "";
      let site_url = "https://qix.cloud";
      const user_req = await fetch(
        site_url + "/wp-admin/admin-ajax.php?action=getCloudFlareAppUserData",
        {
          method: "POST",
          body: JSON.stringify({ user_name: user_name, user_pw: user_pw }),
        }
      );
      let data = await user_req.json();
      if (data.status == "ok") {
        user_data_temp = data;
        twoFactAuthPanel = true;
      } else {
        loginError = data.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function signinWithGoogle() {
    let client_id =
      "567227164069-n724qdj6qo5ri7u2dt922j8o6f536027.apps.googleusercontent.com";
    let redirect_uri = "https%3A//qix.cloud/oauth/app.php";
    let scope =
      "https%3A//www.googleapis.com/auth/userinfo.email https%3A//www.googleapis.com/auth/drive.file https%3A//www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send openid%20email";
    let url =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=" +
      scope +
      "&include_granted_scopes=true&response_type=code&access_type=offline&state=state_parameter_passthrough_value&redirect_uri=" +
      redirect_uri +
      "&client_id=" +
      client_id +
      "&prompt=consent";
    window.parent.location.href = url;
  }
  async function signinWithMicrosoft() {
    const client_id = "0c8ee278-e0c4-4f30-80d5-57f89fa76376";
    const scope = "profile+openid+email+offline_access+User.Read";
    const response_type = "code";
    const redirect_uri = "https://qix.cloud/oauth/microsoft/app.php";
    const url =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" +
      client_id +
      "&scope=" +
      scope +
      "&response_type=" +
      response_type +
      "&redirect_uri=" +
      redirect_uri;
    window.parent.location.href = url;
  }

  function showLoginPopup() {
    loginOnly = true;
    loginError = "";
    loginPanel = true;
  }
  function closeMenu() {
    showMenu = false;
  }
  function handleClickOutsideLogoutPanel() {
    logoutpanel = false;
  }
  function showlogoutpanel() {
    logoutpanel = true;
  }
  async function logoutFunc() {
    let data = localStorage.getItem("user_data");
    if (data) {
      let obj = JSON.parse(data);
      user_data = [];
      user_data["user_id"] = "";
      obj.time = 0;
      localStorage.setItem("user_data", JSON.stringify(obj));
    }
    logoutpanel = false;
  }
  function displayLeftMenuFunc() {
    displayLeftMenu = !displayLeftMenu;
  }
</script>

<svelte:window on:dragenter|preventDefault on:dragover|preventDefault />
<Tailwind />

{#if firstLoading}
  <style>
    .search-new {
      background: #cfe6f4;
      padding: 5px 10px;
      border-radius: 10px;
    }
  </style>
  <main
    class="flex flex-col items-center py-16 bg-gray-100 min-h-screen"
    style="padding-top: 5rem; padding-bottom: 0;"
  >
    <Background />
    <div
      class="fixed z-10 top-0 left-0 right-0 flex justify-center items-center
        bg-gray-200 border-b border-gray-300"
      style="height: 4rem;"
    >
      <div
        on:click={displayLeftMenuFunc}
        on:mouseover={displayLeftMenuFunc}
        class="flex items-center justify-center hidden md:flex"
        style="position: absolute; left: 30px;top: 13px;cursor: pointer;"
      >
        <img
          src="app_icon.svg"
          alt="Sign"
          style="margin-right: 10px; max-width: 40px; width: 100%;"
        />App
      </div>
      <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
        <input
          placeholder="Search or Add New..."
          type="text"
          class="flex-grow bg-transparent search-new"
          bind:value={searchKey}
        />
      </div>
      {#if user_data["user_id"] != ""}
        <div class="relative">
          <label
            class="flex items-center justify-center h-full md:flex cursor-pointer"
            on:click={showlogoutpanel}
            style="background: #185ee0;border-radius: 50%;width: 30px;height: 30px;color: #fff;font-weight: bold;"
          >
            {shortName}
          </label>
          {#if logoutpanel}
            <div
              style="position: absolute; background: #edf2f7; top: 50px; left: 0;padding: 15px;border-radius: 10px;text-align: center;"
            >
              <div
                class="w-full flex items-center"
                style="padding-bottom: 10px;"
              >
                {#if user_data["user_id"].length > 20}
                  <img
                    src="/google1.png"
                    style="width: 20px;margin-right: 0.75rem;"
                  />
                {:else}
                  <img
                    src="/microsoft_logo.png"
                    style="width: 20px;margin-right: 0.75rem;"
                  />
                {/if}
                <span style="margin-right: 20px;"
                  >{user_data["user_email"]}</span
                >
              </div>
              <label
                class="whitespace-no-wrap round py-1 px-3 cursor-pointer"
                style="color:rgb(24, 94, 224)"
                on:click={logoutFunc}
                use:clickOutside
                on:click_outside={handleClickOutsideLogoutPanel}
              >
                Log out
              </label>
            </div>
          {/if}
        </div>
      {:else}
        <button
          on:click={showLoginPopup}
          class="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
            md:px-4 mr-3 md:mr-4 rounded"
        >
          Login
        </button>
      {/if}
    </div>
    {#if loginPanel}
      <div
        transition:fly={{ y: -200, duration: 500 }}
        class="fixed z-10 border-b border-gray-300 bg-white shadow-lg justify-center p-5"
      >
        <div class:hidden={twoFactAuthPanel}>
          <input
            placeholder="Username or Email Address"
            type="text"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={user_name}
          />
          <input
            placeholder="Password"
            type="password"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={user_pw}
          />
          {#if loginError}
            <p class="mb-4">{loginError}</p>
          {/if}
          <div class="flex w-full" style="justify-content: space-between;">
            <button
              on:click={signinWithGoogle}
              class="w-30 py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
              class:hidden={blog_slug != ""}
              style="border: 1px solid #e2e8f0;display:flex;align-items: center;font-size: 13px;"
            >
              <img
                src="/Google__G__Logo.svg.png"
                style="width: 17px;margin-right: 5px;"
              /> Sign in with Google
            </button>
            <button
              on:click={signinWithMicrosoft}
              class="w-30 py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
              class:hidden={blog_slug != ""}
              style="border: 1px solid #e2e8f0;"
            >
              <img src="/microsoft_logo.svg" style="width: 74px;" />
            </button>
            <div class="flex items-center">
              <button
                on:click={() => (loginPanel = false)}
                class="w-30 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3
                  md:px-4 mr-3 md:mr-4 rounded"
              >
                Cancel
              </button>
              <button
                on:click={checkSignIn}
                class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
                  md:px-4 rounded"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
        <div class:hidden={!twoFactAuthPanel}>
          <input
            placeholder="Verification Code"
            type="text"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={verificationCode}
          />
          <div class="flex justify-center items-center w-full">
            <button
              on:click={() => (loginPanel = false)}
              class="w-30 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3
                md:px-4 mr-3 md:mr-4 rounded"
            >
              Cancel
            </button>
            <button
              on:click={checkVerify}
              class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
                md:px-4 mr-3 md:mr-4 rounded"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    {/if}
    {#if user_data["user_id"] != ""}
      <Settings {user_data} />
    {/if}
    <LeftMenu
      on:hide={() => displayLeftMenuFunc()}
      display={displayLeftMenu}
      {user_data}
    />
  </main>
{:else}
  <div
    class="w-full flex-grow flex justify-center items-center"
    style="height: 100vh;position: fixed;background: #fff;top: 0;left: 0;z-index: 99;"
  >
    <img src="/EmoteHi.gif" />
  </div>
{/if}
